import {
  Box,
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Tag,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { TextPlugin } from "gsap/TextPlugin"

import Layout from "../components/layout"
import { IPortfolioHome } from "../components/portfolio"
import { UniformBoxProps } from "../components/utils"
import { ScrollDownButton } from "../components/ui"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"
import { useSessionStorage } from "@avldev/gatsby-theme-core-ui/hooks"

interface PortfolioProps extends PageProps {
  data: {
    portfolioHomes: {
      nodes: IPortfolioHome[]
    }
  }
}

interface PortfolioItemProps {
  home: IPortfolioHome
}

function UniformBox(props: UniformBoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" overflowY="auto" w="800px" {...props} />
}

function PortfolioItem({ home }: PortfolioItemProps): JSX.Element {
  return (
    <LinkBox
      align="middle"
      as={Flex}
      bg="white"
      border="1px"
      borderColor="gray.300"
      flexDirection="column"
      justify="space-evenly"
      p={4}
      rounded="xl"
      shadow="md"
      textAlign="center"
      transition="all 0.2s"
      w="100%"
      _hover={{ shadow: `lg` }}
    >
      <Flex justify="space-between" mt={2} pos="relative" w="100%">
        <Image
          alt={home.images[0].description}
          as={GatsbyImage}
          h={{ base: `300px`, sm: `300px`, md: `350px` }}
          image={home.images[0].gatsbyImageData}
          rounded="xl"
          shadow="md"
          w={{ base: `100%`, sm: `49%` }}
        />
        <Image
          alt={home.images[1].description}
          as={GatsbyImage}
          h={{ base: `0px`, sm: `300px`, md: `350px` }}
          image={home.images[1].gatsbyImageData}
          rounded="xl"
          shadow="md"
          w={{ base: `0%`, sm: `49%` }}
        />
        {home.videos && (
          <Tag
            colorScheme="purple"
            left="5px"
            pos="absolute"
            top="5px"
            variant="solid"
          >
            Video Tour
          </Tag>
        )}
      </Flex>
      <Flex
        align="center"
        flexDir={{ base: `column`, md: `row` }}
        justify="space-between"
        mt={4}
      >
        <LinkOverlay
          as={GatsbyLink}
          color="gray.700"
          to={`/portfolio/${home.slug}/`}
          _hover={{ color: `red.500`, textDecoration: `underline` }}
        >
          <Text
            className="decor"
            fontSize={{ base: `2xl`, md: `3xl` }}
            fontWeight="700"
            pl={{ base: 0, sm: 2 }}
          >
            {home.name}
          </Text>
        </LinkOverlay>
        <Flex
          flexDir="column"
          justify="space-evenly"
          textAlign={{ base: `center`, md: `right` }}
        >
          <Text
            color="gray.500"
            fontSize="xs"
            fontWeight="bold"
            order={{ base: 2, md: 1 }}
            textTransform="uppercase"
          >
            Completed {home.projectFinish}
          </Text>
          {/* {home.community && (
            <Text color="gray.500" fontSize="lg">
              {home.community}
            </Text>
          )} */}
          <Text
            color="red.400"
            fontSize="sm"
            fontWeight="medium"
            order={{ base: 1, md: 2 }}
            textTransform="uppercase"
          >
            {home.cityState}
          </Text>
        </Flex>
      </Flex>
    </LinkBox>
  )
}

// const HERO_HEIGHT = `90vh`
const HERO_HEIGHT = `calc(100vh - 4.5rem)`

function Portfolio({ data, location }: PortfolioProps): JSX.Element {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(TextPlugin)

  const portfolioRef = React.useRef<HTMLDivElement>()

  const { nodes: homes } = data.portfolioHomes

  const [animateIntro, setAnimateIntro] = useSessionStorage<boolean>(
    `portfolioIntro`,
    false
  )
  const heroContentWrapperRef = React.useRef<HTMLDivElement>(null)
  const introHeadingRef1 = React.useRef<HTMLSpanElement>(null)
  const introHeadingRef2 = React.useRef<HTMLSpanElement>(null)
  const subTitleRef = React.useRef<HTMLDivElement>(null)
  const scrollButtonRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!animateIntro) {
      gsap.set(introHeadingRef1.current, { text: `` })
      gsap.set(introHeadingRef2.current, { text: `` })

      gsap.from(heroContentWrapperRef?.current, {
        delay: 0.5,
        duration: 4,
        ease: `power4.out`,
        background: `white`,
      })

      gsap.to(introHeadingRef1.current, {
        delay: 1.5,
        duration: 1,
        text: `Project`,
      })

      gsap.to(introHeadingRef2.current, {
        delay: 2.5,
        duration: 1,
        text: `Portfolio`,
      })

      gsap.from(subTitleRef.current, {
        delay: 3.5,
        duration: 2,
        opacity: 0,
      })

      gsap.from(scrollButtonRef?.current, {
        delay: 4.5,
        duration: 1.5,
        ease: `power2.out`,
        opacity: 0,
      })

      setAnimateIntro(true)
    }
  }, [])

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Need ideas for building your custom home? Browse through some of our favorite builds and find inspiration.",
          image: `${location.origin}/sundog-homes-lake-glenville-build.jpg`,
          imageAlt: `A Sundog home on Lake Glenville`,
        }}
        title="Project Portfolio, Photos and Videos | Sundog Homes"
        url={location.href}
      />
      <Box bg="white" h={HERO_HEIGHT} pos="relative" w="100vw">
        <Box h="100%" w="100%">
          <StaticImage
            alt="The view from the porch of a Sundog-built home on Lake Glenville."
            className="squared"
            src="../images/sundog-homes-lake-glenville-build.jpg"
            layout="fullWidth"
            style={{ height: HERO_HEIGHT, width: `100vw` }}
          />
        </Box>
        <Box
          bg="blackAlpha.400"
          h="100%"
          justify="center"
          left="0"
          pb={{ base: `18%`, lg: `15%` }}
          pos="absolute"
          ref={heroContentWrapperRef}
          top="0"
          w="100%"
        >
          <Box
            maxW="85vw"
            mt={[`80%`, `60%`, `40%`, `25%`]}
            mx="auto"
            w="400px"
          >
            <Heading
              as="h1"
              color="red.300"
              fontSize="6xl"
              fontWeight="extrabold"
              w="auto"
            >
              <span ref={introHeadingRef1}>Project</span>
              <br />
              <span ref={introHeadingRef2}>Portfolio</span>
            </Heading>
            <Text color="white" fontSize="2xl" ref={subTitleRef}>
              Bespoke homes in beautiful locations, built for wonderful people
            </Text>
          </Box>
        </Box>
        <Box
          bottom="50px"
          left="calc(50vw - 24px)"
          pos="absolute"
          ref={scrollButtonRef}
        >
          <ScrollDownButton offsetY={72} target={portfolioRef} />
        </Box>
      </Box>
      <Box bg="gray.50" py={12} ref={portfolioRef}>
        <UniformBox fontSize={{ lg: `xl` }} fontWeight="medium">
          <Text fontFamily="heading" fontSize="4xl" fontWeight="black" mb={2}>
            Foreword
          </Text>
          <Box bg="red.500" h="4px" mb={6} rounded="full" w="50px" />
          <Text>
            For us, a look through our portfolio always ends with a trip down
            memory lane. You see, around here each project is like a new season:
            the arc under which all of our other stories play out; a reference
            to a discreet period of time ("That was back during the Bacons'
            build"). Everything we do revolves around our projects — they're the
            center of our little universe.
          </Text>
          <Text mt={6}>
            While the homes on display here (with permission from their owners)
            represent only a small sample of our projects, they still have a lot
            to say about our consistency, openness, passion, and constant
            dedication to quality. So, in true Sundog style, we'll let them do
            the talking.
          </Text>
          <Text fontFamily="heading" fontStyle="italic" mr={6} mt={6}>
            — The Sundog Homes Team
          </Text>
        </UniformBox>
        <UniformBox mt={12}>
          <Text fontFamily="heading" fontSize="4xl" fontWeight="black" mb={2}>
            Projects
          </Text>
          <Box bg="red.500" h="4px" mb={6} rounded="full" w="50px" />
          <Flex
            align="middle"
            flexDirection="row"
            flexWrap="wrap"
            justify="start"
            pb={4}
            w="100%"
          >
            {homes.map((home: IPortfolioHome, index: number) => (
              <Box key={index} px={2} py={6} w="100%">
                <PortfolioItem home={home} />
              </Box>
            ))}
          </Flex>
        </UniformBox>
      </Box>
    </Layout>
  )
}

export default Portfolio

export const query = graphql`
  query {
    portfolioHomes: allContentfulPortfolioHome(
      sort: { fields: projectFinish, order: DESC }
    ) {
      nodes {
        ...PortfolioHome
      }
    }
  }
`
